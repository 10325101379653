const header = document.querySelector('header')

window.addEventListener('scroll', ()=>{
   if (scrollY > 90) {
      header.classList.add('scrollOn')
   }else{
      header.classList.remove('scrollOn')
   }
})

window.addEventListener('load', ()=>{
   if (scrollY > 90) {
      header.classList.add('scrollOn')
   }else{
      header.classList.remove('scrollOn')
   }
})

const hamburger = document.querySelector('.hamburger');
const nav = document.querySelector('header nav')

hamburger.onclick=()=>{
   hamburger.classList.toggle('active');
   nav.classList.toggle('active');
}